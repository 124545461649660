@import url('https://fonts.googleapis.com/css2?family=Chocolate+Classical+Sans&display=swap');

@tailwind base;

html {
    scroll-behavior: smooth;
    font-family: "Chocolate Classical Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    /* font-family: "Signika Negative", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal; */
}

@tailwind components;
@tailwind utilities;